import {
  primaryTextColor,
  tertiaryTextColor,
  defaultMobileFontSize,
  defaultDesktopFontSize,
  secondaryBgColor,
  tertiaryBgColor
} from "src/assets/jss/material-kit-react.js"
//import tooltip from "src/assets/jss/material-kit-react/tooltipsStyle.js";

import headerLinksStyle from "src/assets/jss/material-kit-react/components/headerLinksStyle.js"

const headerRightLinksStyle = theme => ({
  ...headerLinksStyle(theme),
  rightNavList: {
    "& li": {
      display: "block",
      width: "100%",
      "& *": {
        textTransform: "lowercase",
        color: primaryTextColor,
        letterSpacing: "0.1rem !important",
        fontWeight: "500"
      },
      "&.Mui-selected": {
        backgroundColor: "transparent",
        "& span": {
          fontWeight: "600"
        },
        "&:hover,&:focus": {
          backgroundColor: "transparent",
          "& *": {
            color: primaryTextColor
          }
        },
        "& .MuiButton-root": {
          backgroundColor: secondaryBgColor,
          cursor: "auto"
        }
      },
      "&:hover,&:focus": {
        backgroundColor: "transparent",
        "& span": {
          fontWeight: "600"
        }
      },
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "none" /** was block but took out per HbK */,
        height: "1px",
        marginLeft: "15px",
        backgroundColor: tertiaryBgColor
      },
      "& .MuiButton-root": {
        color: "inherit",
        position: "relative",
        padding: "0.9375rem 1.5rem",
        fontWeight: "400",
        fontSize: defaultMobileFontSize,
        letterSpacing: "0.05rem",
        textTransform: "uppercase",
        borderRadius: "80px",
        lineHeight: "20px",
        textDecoration: "none",
        margin: "0px",
        marginLeft: "15px",
        marginBottom: "8px",
        marginTop: "8px",
        width: "calc(100% - 30px)",
        display: "block",
        textAlign: "left",
        "&:hover,&:focus": {
          background: tertiaryBgColor,
          "& *": {
            color: tertiaryTextColor
          }
        },
        "& > span:first-child": {
          justifyContent: "flex-start"
        },
        [theme.breakpoints.up("md")]: {
          fontSize: defaultDesktopFontSize,
          padding: "1.5rem 2.5rem"
        }
      }
    }
  }
})

export default headerRightLinksStyle

import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  cardHeader,
  getCalcRem,
} from "src/assets/jss/material-kit-react.js"
import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme()

const cardHeaderStyle = {
  cardHeader: {
    ...cardHeader,
    paddingLeft: getCalcRem(32),
    paddingRight: getCalcRem(16),
    border: "0",
    marginBottom: "0",
    [theme.breakpoints.up("md")]: {
      paddingLeft: getCalcRem(48),
      lineHeight: "64px !important",
    },
    "& .button-filter-properties": {
      justifySelf: "flex-end",
      marginLeft: "auto",
      padding: "0",
      "& *": {
        fontSize: getCalcRem(16) + " !important",
        [theme.breakpoints.up("md")]: {
          fontSize: getCalcRem(22) + " !important",
        },
      },

      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  cardHeaderPlain: {
    marginLeft: "0px",
    marginRight: "0px",
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
}

export default cardHeaderStyle

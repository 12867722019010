import {
  container,
  primaryColor,
  primaryBgColor,
  secondaryBgColor,
  secondaryColorRGB,
  secondaryBoxShadow,
  secondaryTextColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  dashboardColor,
  transition,
  boxShadow,
  drawerWidth,
  appMinWidth,
  getCalcRem,
} from "src/assets/jss/material-kit-react.js"

const headerStyle = theme => ({
  appBar: {
    display: "flex",
    border: "0",
    padding: "0",
    color: secondaryTextColor,
    width: "100%",
    backgroundColor: "transparent",
    ...appMinWidth,
    "& .MuiToolbar-root": {
      flexDirection: "row",
    },
    "& .MuiToolbar-gutters": {
      paddingLeft: "0",
      paddingRight: getCalcRem(32),
    },
    "& .MuiIconButton-root": {
      borderRadius: "0",

      "&:hover": {
        backgroundColor: "transparent",
        borderRadius: "0",
      },
      "&.homes-logo-wrapper": {
        marginTop: "5%",
        padding: "0",
        marginRight: "auto",
        marginTop: theme.spacing(2),
        "& .MuiTouchRipple-root": {
          display: "none",
        },

        "& .homes-logo img": {
          height: "101px",
          "@media (max-width: 359px)": {
            height: "89px" /** height: "106px" **/,
          },
          "@media (max-width: 319px)": {
            height: "74px" /** height: "91px" */,
          },
          "@media (min-width: 768px)": {
            height: "117px" /** height: "91px" */,
          },
          "@media (min-width: 1024px) and (min-height: 700px)": {
            height: "163px" /** height: "201px", */,
            display: "inline-block",
          },
        },
        "& .homes-logo-text": {
          margin: "65px 0 0 12px",
          "@media (max-width: 400px)": {
            margin: "68px 0 0 12px",
          },
          "@media (min-width: 768px)": {
            margin: "76px 0 0 12px",
          },
          "@media (min-width: 1024px)": {
            margin: "110px 0 0 12px",
          },
        },
        "& .homes-logo-text img": {
          height: "28px",
          "@media (max-width: 400px)": {
            height: "22px",
          },
          "@media (min-width: 768px)": {
            height: "32px",
          },
          "@media (min-width: 1024px) and (min-height: 700px)": {
            height: "41px",
          },
        },
      },
    },
  },
  iconButton: {
    position: "absolute",
    right: "30px",
    top: "25px",
    [theme.breakpoints.up("sm")]: {
      position: "relative",
    },
    padding: "0",
    "&:hover": {},
    "& .MuiSvgIcon-root": {
      fill: primaryBgColor,
      fontSize: getCalcRem(22) + " !important",
      [theme.breakpoints.up("md")]: {
        fontSize: getCalcRem(32) + " !important",
      },
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  },
  absolute: {
    position: "absolute",
    zIndex: "1100",
  },
  fixed: {
    position: "fixed",
    zIndex: "1100",
  },
  container: {
    ...container,
    backgroundColor: secondaryBgColor,
    justifyContent: "flex-end",
    display: "flex",
    flexWrap: "nowrap",
  },
  flex: {
    flex: 1,
  },

  appResponsive: {
    margin: "20px 10px",
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)",
  },
  info: {
    backgroundColor: infoColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)",
  },
  success: {
    backgroundColor: successColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)",
  },
  warning: {
    backgroundColor: warningColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)",
  },
  danger: {
    backgroundColor: dangerColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)",
  },
  dashboard: {
    backgroundColor: dashboardColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)",
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    paddingTop: "25px",
    color: "#FFFFFF",
  },
  dark: {
    color: "#FFFFFF",
    backgroundColor: "#212121 !important",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)",
  },
  white: {
    border: "0",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: "#555",
    backgroundColor: "#fff !important",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
  },
  drawerPaper: {
    border: "none",
    backgroundColor: primaryBgColor,
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0",
    paddingLeft: "0",
    paddingTop: "22px",
    borderBottomLeftRadius: "80px",
    borderTopLeftRadius: "80px",
    ...transition,
    [theme.breakpoints.up("md")]: {
      width: "360px",
    },
  },
})

export default headerStyle

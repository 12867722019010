/*eslint-disable*/
import React from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// nodejs library that concatenates classes
import classNames from "classnames"
// material-ui core components
import { List, ListItem } from "@material-ui/core"

import BrandingWatermarkIconDesktop from "src/assets/images/watermark_footer_homes_desktop.png"
import BrandingWatermarkIcon from "src/assets/images/watermark_footer_homes_mobile.png"

import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite"

import styles from "src/assets/jss/material-kit-react/components/footerStyle.js"

const useStyles = makeStyles(styles)

export default function Footer(props) {
  const classes = useStyles()
  const { whiteFont, globalState } = props

  const scrollToTop = () => {
    if (!globalState.isBrowser) return
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    return true
  }

  return (
    <footer className={classes.footer}>
      <div className={classNames(classes.container, "container")}>
        <div className="copy-links-wrapper">
          <List className="links-wrapper">
            <ListItem>
              <a
                href="/terms/"
                title="Navigate to Terms page"
                data-analytics-label="Footer Terms Link"
              >
                Terms
              </a>
            </ListItem>
            <ListItem>
              <a
                href="/privacy/"
                title="Navigate to Privacy page"
                data-analytics-label="Footer Privacy Link"
              >
                Privacy
              </a>
            </ListItem>
            <ListItem>
              <div className="copy">
                &copy; {1900 + new Date().getYear()} hbk
              </div>
            </ListItem>
          </List>
        </div>
        <div className="branding-watermark-wrapper">
          <img
            className="branding-watermark"
            src={BrandingWatermarkIcon}
            alt="Watermark Image"
          />
          {/**
          <img
            className="branding-watermark-lg"
            src={BrandingWatermarkIconDesktop}
            alt="Watermark Image"
          /> */}
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
}

import {
  container,
  secondaryBgColor,
  primaryTextColor,
  defaultMobileFontSize,
  defaultDesktopFontSize,
  getCalcRem /** argument integer of pixels desired */,
} from "src/assets/jss/material-kit-react.js"

const footerStyle = theme => ({
  footer: {
    display: "flex",
    justifySelf: "flex-end",
    //position: "absolute",
    marginTop: "auto",
    zIndex: "5",
    width: "100%",
    /* backgroundColor: secondaryBgColor, */

    "& .branding-watermark": {
      marginTop: "10%",
      width: "150px",
      "@media (max-width: 319px)": {
        width: "110px",
      },
      "@media (max-width: 359px)": {
        width: "133px",
      },
      "@media (min-width: 768px)": {
        width: "174px",
      },
      "@media (min-width: 1024px) and (min-height: 700px)": {
        width: "243px",
      },
    },

    "& .container": {
      backgroundColor: secondaryBgColor,
      display: "flex",
      flexDirection: "row",
      ...container,
    },
    "& .copy-links-wrapper": {
      display: "flex",
      alignItems: "flex-end",
      marginLeft: "auto",
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(1),
      "@media (max-width: 400px)": {
        marginRight: "0",
      },
      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(4),
      },

      "& .links-wrapper": {
        "& *": {
          fontSize: getCalcRem(16),
          color: "#ababab",
        },
        "& li": {
          display: "inline-block",
          padding: "0px",
          width: "auto",
        },
        "& a": {
          backgroundColor: "transparent",
          textTransform: "lowercase",
          display: "inline-block",
          marginRight: theme.spacing(2),
          "@media (max-width: 400px)": {
            marginRight: theme.spacing(1),
          },
          [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(4),
            //fontSize: defaultDesktopFontSize
          },
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },

      "& .copy": {},
    },
  },
})
export default footerStyle

import React from "react"
import { Link } from "gatsby"

// nodejs library that concatenates classes
import classNames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Hidden from "@material-ui/core/Hidden"
import Drawer from "@material-ui/core/Drawer"
// @material-ui/icons
import Menu from "@material-ui/icons/Menu"
// core components
import SEO from "src/components/SEO"
import styles from "src/assets/jss/material-kit-react/components/headerStyle.js"
import { secondaryBgColor } from "src/assets/jss/material-kit-react"

//import BrandingWatermarkIconDesktop from "src/assets/images/logo_homes_desktop.png"
import BrandingWatermarkIcon from "src/assets/images/logo_homes_mobile.png"
//import BrandingTextDesktop from "src/assets/images/logo_text_homes_desktop.png"
import BrandingText from "src/assets/images/logo_text_homes_mobile.png"

const useStyles = makeStyles(styles)

export default function Header(props) {
  const {
    rightLinks,
    leftLinks,
    scaffolding,
    siteVariables,
    globalState,
    location,
  } = props
  const classes = useStyles()

  const appBarClasses = classNames({
    [classes.appBar]: true,
  })

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const brandComponent = (
    <IconButton className="homes-logo-wrapper">
      <Link
        className="homes-logo"
        to="/"
        title={"Navigate to: Home Page"}
        data-analytics-label="Site Header Logo Link"
      >
        <img
          className="homes-logo"
          src={BrandingWatermarkIcon}
          alt={siteVariables.siteTitle}
        />
        {/**
        <img
          className="homes-logo-lg"
          src={BrandingWatermarkIconDesktop}
          alt={siteVariables.siteTitle}
        />
         */}
      </Link>
      <Link
        className="homes-logo-text"
        to="/"
        title={"Navigate to: Home Page"}
        data-analytics-label="Site Header Logo Text Link"
      >
        <img src={BrandingText} alt={siteVariables.siteTitle} />
      </Link>
    </IconButton>
  )

  return (
    <AppBar className={appBarClasses} position="relative" elevation={0}>
      <SEO
        scaffolding={scaffolding}
        siteVariables={siteVariables}
        location={location}
      />

      <Toolbar /*className={}*/>
        {leftLinks !== undefined ? brandComponent : null}
        {leftLinks !== undefined ? leftLinks : brandComponent}
        <IconButton
          className={classes.iconButton}
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          alt="Open Main Menu"
          title="Open Main Menu"
          data-analytics-label="Open Main Menu"
        >
          <Menu />
        </IconButton>
      </Toolbar>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={mobileOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerToggle}
      >
        <div className={classes.appResponsive}>{rightLinks}</div>
      </Drawer>
    </AppBar>
  )
}

Header.defaultProp = {
  color: secondaryBgColor,
}

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "primaryBgColor",
    "secondaryBgColor",
    "darkPrimaryColor",
    "secondaryColor",
    "tertiaryColor",
    "primaryTextColor",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "dashboard",
    "dark",
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "primaryBgColor",
      "secondaryBgColor",
      "darkPrimaryColor",
      "secondaryColor",
      "tertiaryColor",
      "primaryTextColor",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "dashboard",
      "dark",
    ]).isRequired,
  }),
}

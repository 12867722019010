/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iF]ragment" }]*/

import React from "react"
//import PropTypes from "prop-types"

// nodejs library that concatenates pageClasses
//import classNames from "classnames"
// @material-ui/core components
import useMediaQuery from "@material-ui/core/useMediaQuery"

// core components
import Header from "src/components/Header/Header.js"
import Footer from "src/components/Footer/Footer.js"
//import GridContainer from "src/components/Grid/GridContainer.js"
import HeaderLinksRight from "src/components/Header/HeaderLinksRight.js"

export default function Layout({
  //pageClasses,
  location,
  scaffolding,
  siteVariables,
  globalState,
  pageHeader,
  ...rest
}) {
  const isLandscape = useMediaQuery("(orientation:landscape)")

  return (
    <div className="page-content-wrapper">
      <Header
        rightLinks={
          <HeaderLinksRight siteVariables={siteVariables} location={location} />
        }
        scaffolding={scaffolding}
        siteVariables={siteVariables}
        globalState={globalState}
        pageHeader={pageHeader}
        location={location}
        {...rest}
      />
      <section className="main-content-wrapper">
        {rest.children(isLandscape)}
      </section>
      <Footer globalState={globalState} />
    </div>
  )
}

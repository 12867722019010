import React from "react"

// @material-ui/core components
import { List, MenuItem } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// core components
import Button from "src/components/CustomButton"

import styles from "src/assets/jss/material-kit-react/components/headerRightLinksStyle.js"

const useStyles = makeStyles(styles)

export default function HeaderLinksRight(props) {
  const classes = useStyles()
  const { siteVariables, location } = props

  return (
    <List className={classes.rightNavList}>
      {siteVariables.ref_mainNavigationPages.map((p, i) => {
        const path =
          "/" +
          (p.scaffolding.text_slug !== null
            ? p.scaffolding.text_slug + "/"
            : "")
        return (
          <MenuItem
            key={i}
            disableGutters
            selected={location.pathname === path ? true : false}
          >
            <Button
              href={path}
              color="transparent"
              title={"Navigate to: " + p.headerText_page + " page"}
              data-analytics-label={"Main menu link to " + p.headerText_page}
            >
              {p.headerText_page}
            </Button>
          </MenuItem>
        )
      })}
    </List>
  )
}

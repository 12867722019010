import {
  bodyStyles,
  container,
  primaryBgColor,
  primaryTextColor,
  secondaryBgColor,
  defaultDesktopFontSize,
  defaultMobileFontSize,
  defaultXSMobileFontSize,
  getCalcRem
} from "src/assets/jss/material-kit-react.js"

import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme()

const defaultPageStyles = {
  /** this file gets imported inside of a jss body wrapper.
      The CSS below outside of a selector will be applied to the body tag */
  background: primaryBgColor,
  ...bodyStyles,

  "& #___gatsby, & #gatsby-focus-wrapper, & .page-content-wrapper": {
    justifyContent: "center",
    ...bodyStyles
  },

  "& .page-content-wrapper": {
    ...container,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: secondaryBgColor,
    height: "100vh",
    "&  *, & .MuiTypography-h4": {
      color: primaryTextColor,
      fontSize: defaultMobileFontSize,
      fontWeight: "400",
      //lineHeight: getCalcRem(22),
      letterSpacing: ".02rem",
      [theme.breakpoints.up("md")]: {
        fontSize: defaultDesktopFontSize
        //lineHeight: getCalcRem(32),
      },
      "@media (max-width: 400px)": {
        fontSize: defaultXSMobileFontSize
        //lineHeight: getCalcRem(22),
      }
    },
    "& .cardBody": {
      "& .MuiGrid-container.padded": {
        padding: theme.spacing(4),
        [theme.breakpoints.up("md")]: {
          paddingLeft: 0,
          paddingRight: 0
        }
      },
      "& .image-gallery-slide": {
        transition: "none !important"
      },
      "& .image-gallery-slide.center": {},
      "& .image-gallery-slide.left, & .image-gallery-slide.right ": {},

      "& .image-gallery-thumbnail:hover, & .image-gallery-thumbnail:active, & .image-gallery-thumbnail:focus": {
        /** had to change in plug-in to make work for some odd reason */
        border: "3px solid " + primaryBgColor,
        "@media (max-width: 768px)": {
          border: "3px solid " + primaryBgColor
        },
        [theme.breakpoints.up("md")]: {
          border: "4px solid " + primaryBgColor
        }
      }
    },
    "& form .MuiButton-root": {
      borderRadius: "30px"
    },
    "& p": {
      marginBottom: theme.spacing(2)
    },
    "& .MuiTypography-gutterBottom": {
      marginBottom: theme.spacing(3)
    },
    "& .MuiTypography-gutterBottomSmall": {
      marginBottom: theme.spacing(1)
    },
    "& .MuiButtonBase-root": {
      boxShadow: "none",
      "& span": {
        textTransform: "lowercase",
        fontWeight: "600",
        "&:hover": {
          fontWeight: "600"
        }
      }
    },
    "& .MuiTouchRipple-root": {
      display: "none"
    },
    "& .main-content-wrapper": {
      position: "relative",
      zIndex: "0",
      backgroundColor: secondaryBgColor,
      "& a": {
        color: primaryTextColor,
        fontSize: defaultMobileFontSize,
        textDecoration: "none",
        [theme.breakpoints.up("md")]: {
          fontSize: defaultDesktopFontSize
        },
        "&:hover": {
          textDecoration: "underline",
          cursor: "pointer"
        }
      },
      "& .MuiGrid-container": {
        marginLeft: "auto",
        marginRight: "auto",
        alignContent: "space-around"
      }
    },
    "& .constrained-image-wrapper": {
      position: "relative !important",
      display: "block",
      width: "100%",
      maxWidth: "100%",
      height: "auto",
      maxHeight: "100%",
      padding: 0,
      margin: 0,
      overflow: "hidden",
      "&.img-2x3::before": {
        paddingTop: "66.67%"
      },
      "&::before": {
        display: "block",
        content: '""'
      },
      "& .constrained-image": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        padding: 0,
        margin: 0
      }
    },
    "& .constrained-image-wrapper, .constrained-image-wrapper .constrained-image": {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: 0
    }
  }
}

export default defaultPageStyles

import { createMuiTheme } from "@material-ui/core/styles"
const theme = createMuiTheme()

const cardBodyStyle = {
  cardBody: {
    [theme.breakpoints.up("sm")]: {}
  }
}

export default cardBodyStyle

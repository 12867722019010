import {
  tertiaryBgColor,
  primaryTextColor,
  secondaryTextColor,
  cardTitle,
  defaultMobileFontSize,
  defaultDesktopFontSize,
  getCalcRem
} from "src/assets/jss/material-kit-react.js"

const cardStyle = theme => ({
  card: {
    border: "0",
    color: primaryTextColor,
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    //transition: "all 300ms linear",
    fontSize: defaultMobileFontSize,
    [theme.breakpoints.up("md")]: {
      fontSize: defaultDesktopFontSize
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      "& .MuiTypography-subtitle1": {
        fontSize: getCalcRem(12)
      },
      "& .MuiTypography-subtitle2": {
        fontSize: getCalcRem(8),
        fontWeight: "normal",
        textTransform: "capitalize"
      }
    },
    "& .MuiCardActions-root": {
      padding: "0"
    },
    "& .MuiSvgIcon-root": {
      fill: primaryTextColor
    },
    "& a": {
      color: primaryTextColor,
      fontSize: getCalcRem(8)
    }
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none"
  },
  cardCarousel: {
    overflow: "hidden"
  },
  cardTitle: {
    ...cardTitle,
    color: primaryTextColor
  },
  buttonWrap: {
    display: "grid",
    margin: "0 auto",
    "& a": {
      margin: "0 auto",
      width: "30em",
      [theme.breakpoints.up("md")]: {
        width: "18rem"
      }
    },
    "& button": {
      width: "100%"
    }
  }
})

export default cardStyle

import imagesStyles from "src/assets/jss/material-kit-react/imagesStyles.js"
import {
  primaryTextColor,
  primaryBgColor,
  tertiaryBgColor,
  secondaryBgColor,
  defaultMobileFontSize,
  defaultDesktopFontSize,
  warningColor,
  getCalcRem,
} from "src/assets/jss/material-kit-react"

import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme()

const defaultComponentStyles = {
  ...imagesStyles,
  cardBody: {
    margin: "0 auto",
    width: "100%",
    "& input: invalid": {
      //backgroundColor: primaryTextColor
    },

    "& input:valid": {
      //backgroundColor: primaryTextColor
    },
    "& .pageSubheader-root,& .MuiTypography-h5 ": {
      fontWeight: "500" + " !important",
      textTransform: "lowercase",
    },
    "& .pageSubheader-root": {
      fontSize: getCalcRem(22) + " !important",
      [theme.breakpoints.up("md")]: {
        fontSize: getCalcRem(28) + " !important",
      },
    },
    "& .MuiTypography-h5, ": {
      fontSize: getCalcRem(18) + " !important",
      [theme.breakpoints.up("md")]: {
        fontSize: getCalcRem(22) + " !important",
      },
    },
    "& .MuiCardActions-root": {
      marginTop: getCalcRem(16),
    },
    "& .MuiCardActions-root .MuiButton-label": {
      letterSpacing: "0.00735rem",
      fontSize: getCalcRem(18),
      textTransform: "Capitalize",
      [theme.breakpoints.up("md")]: {
        fontSize: defaultDesktopFontSize,
      },
    },
    "& .MuiTypography-body1, & .MuiTypography-body1 p, & .MuiFormLabel-root, & .MuiFormControlLabel-label": {
      letterSpacing: "unset",
      lineHeight: getCalcRem(22),
      [theme.breakpoints.up("md")]: {
        lineHeight: getCalcRem(32),
      },
    },
    "& .MuiCollapse-container": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      paddingTop: theme.spacing(2),
      color: primaryTextColor,
    },
    "& .field-set": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid " + tertiaryBgColor + " !important",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "2px solid " + primaryBgColor + " !important",
    },
    "& .MuiFormLabel-root, & .MuiFormControlLabel-label, & .MuiFormHelperText-root": {
      color: primaryTextColor,
    },
    "& .MuiFormLabel-root.Mui-error, & .MuiFormHelperText-root.Mui-error": {
      color: warningColor,
    },
    "& .icon-wrapper, & .icon-wrapper *": {
      height: getCalcRem(32),
      width: getCalcRem(32),
      borderRadius: "21px",
      [theme.breakpoints.up("md")]: {
        height: getCalcRem(42),
        width: getCalcRem(42),
      },
    },
    "& .icon-wrapper": {
      "& button, & .icon-inner-wrapper": {
        backgroundColor: primaryTextColor,

        //opacity: ".8"
      },
      "& button svg, & .icon-inner-wrapper svg": {
        fill: secondaryBgColor,
        border: "3px solid " + secondaryBgColor,
      },
    },
    "& .icon-button-wrapper:hover": {
      "& button, & .icon-inner-wrapper": {
        backgroundColor: secondaryBgColor,
        //opacity: ".8"
      },
      "& button svg, & .icon-inner-wrapper svg": {
        fill: primaryTextColor,
        borderColor: tertiaryBgColor,
      },
    },
    "& .homes-list-wrapper, & ul": {
      width: "100%",
      listStyle: "disc",
      paddingLeft: "20px",
      display: "flex",
      flexWrap: "wrap",

      "& .MuiListSubheader-root": {
        fontWeight: "500",
        lineHeight: "inherit",
        textTransform: "capitalize",
        color: primaryTextColor,
        marginLeft: "-20px",
        marginBottom: ".25rem",
        width: "100%",
        flex: "none",
        fontSize: defaultMobileFontSize,
        [theme.breakpoints.up("md")]: {
          fontSize: defaultDesktopFontSize,
        },
      },
      "& li": {
        padding: "0",
        display: "list-item",
        fontWeight: "normal",
        flex: "1 0 50%",
        marginBottom: ".25rem",
        fontSize: defaultMobileFontSize,
        [theme.breakpoints.up("md")]: {
          fontSize: defaultDesktopFontSize,
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "1rem",
        },

        "&:nth-child(even)": {
          paddingRight: "1rem",
        },
        "& .MuiListItemText-root": {
          margin: "0 0 0 -5px",
        },
        "& .MuiListItemText-primary": {
          whiteSpace: "nowrap",
          fontSize: ".5rem",
        },
      },
    },
    "& .homes-learn-more-wrapper": {
      marginLeft: "15px",
      width: "100%",
      "& button": {
        background: "transparent",
        border: "0",
        fontSize: defaultMobileFontSize,
        padding: "0",
        color: primaryTextColor,
      },
      "& .linkText": {
        width: "2.5rem",
      },
      "& .expandClose": {
        "& .expandedText": {
          display: "none",
        },
        "& .linkText": {
          lineHeight: "26px",
        },
        "& svg": {
          transform: "rotate(0deg)",
          transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
          }),
        },
      },
      "& .expandOpen": {
        "& .collapsedText": {
          display: "none",
        },
        "& .expandedText": {
          display: "inline",
        },
        "& .linkText": {
          lineHeight: "22px",
        },
        "& svg": {
          transform: "rotate(180deg)",
        },
      },
    },
  },
}

export default defaultComponentStyles
